const faqs = [
  {
    question: 'What does unlimited design services mean?',
    answer: 'It means you can submit an unlimited number of requests to your design queue at any time, and your designer will work through them one by one. No more paying per project or per hour.',
  },
  {
    question: 'I need my designs ASAP—can you help?',
    answer: <>Of course! Send us an email at <a href="mailto:hello@designproject.io" className="underline">hello@designproject.io</a> telling us what you need and we can discuss quick turnaround times.</>,
  },
  {
    question: 'What types of projects do you work on?',
    answer: 'Our bread and butter is top-tier UX/UI designs that solve user problems and drive more revenue for you. Need more? We also craft bold brands and deliver high-impact marketing solutions, from landing pages to custom assets.',
  },
  {
    question: 'Not sure yet about a subscription?',
    answer: 'A Sprint-Plan could be the best match for your business! Ideal for Deadline-Driven projects, needing quick turnarounds without long-term commitments. If you want to learn more, please contact us!',
  }
]

export default function Faqs() {
  return (
    <section className="bg-jungle-700 py-16 md:py-20">
      <div className="container mx-auto px-6">
        
        <div className="grid grid-cols-1 md:grid-cols-[1fr_2fr] items-center gap-10 md:gap-0">
          <div>
            <div className="max-w-72">
              <h2 className="text-neutral-50 text-5xl md:mb-6">
                We are<br/><i className="font-semibold">often asked...</i>
              </h2>
              <a href="https://designproject.io/get-started" target="_blank" className="font-text font-semibold text-secondary-jungle uppercase h-[50px] hidden md:flex items-center justify-center rounded-md bg-secondary-menthol hover:bg-menthol-100 focus:outline-none focus:ring focus:ring-menthol-100 transition duration-150 ease-in-out">
                Book a call now!
              </a>
            </div>
          </div>

          <ul>
            {faqs.map((faq, index) => (
              <li key={index} className="border-b border-secondary-jungle pt-6 pb-6 first:pt-0 last:pb-0">
                <h3 className="text-jungle-200 text-2xl font-semibold mb-2">
                  {faq.question}
                </h3>
                <p className="text-neutral-200 text-xl leading-[135%]">
                  {faq.answer}
                </p>
              </li>
            ))}
          </ul>

          <div className="md:hidden">
            <a href="https://designproject.io/get-started" target="_blank" className="font-text font-semibold text-secondary-jungle uppercase h-[50px] flex items-center justify-center rounded-md bg-secondary-menthol">
              Book a call now!
            </a>
          </div>
        </div>

      </div>
    </section>
  )
}